// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $tablet-width) {
    .widget {

        // Hero Banner

        &-banner-image-wrapper {
            &.small {
                height: $widget-hero-banner-mobile-small__height--medium;
            }

            &.medium {
                height: $widget-hero-banner-mobile-medium__height--medium;
            }

            &.large {
                height: $widget-hero-banner-mobile-large__height--medium;
            }
        }
    }
}
