// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .widget {

        // Hero Banner

        &-banner-image-wrapper {
            &.small,
            &.medium,
            &.large {
                height: auto;
            }
            
            &.full_height {
                height: calc(100vh - #{$page__padding-top--large});
            }
        }
    
        &-banner-label {
            min-width: $widget-banner-label__min-width--large;
            padding: $widget-banner-label__padding--large;
            font-size: $widget-banner-label__font-size--large;
            line-height: $widget-banner-label__line-height--large;

            &::after {
                border-width: $widget-banner-label-after__border-width--large;
                margin-bottom: $widget-banner-label-after__margin-bottom--large;
            }
        }

        &-banner-content-wrapper-overlay {
            padding: $widget-hero-banner-content-wrapper__padding--large;
        }

        &-content-position {
            width: $widget-bannner-content__width--large;
    
            &.desktop-top {
                &-left {
                    top: 0;
                    bottom: auto;
                    left: 0;
                    transform: none;
                }

                &-center {
                    top: 0;
                    bottom: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &-right {
                    top: 0;
                    right: 0;
                    bottom: auto;
                    left: auto;
                    transform: none;
                }
            }

            &.desktop-center {
                &-left {
                    top: 50%;
                    bottom: auto;
                    left: 0;
                    transform: translateY(-50%);
                }

                &-center {
                    top: 50%;
                    bottom: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &-right {
                    top: 50%;
                    right: 0;
                    bottom: auto;
                    left: auto;
                    transform: translateY(-50%);
                }
            }

            &.desktop-bottom {
                &-left {
                    top: auto;
                    bottom: 0;
                    left: 0;
                    transform: none;
                }

                &-center {
                    top: auto;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &-right {
                    top: auto;
                    right: 0;
                    bottom: 0;
                    left: auto;
                    transform: none;
                }
            }
        }
    
        &-brand-image-wrapper {
            position: absolute;
            z-index: 2;
            top: $widget-hero-banner-brand-image-wrapper-position__top;
            right: $widget-hero-banner-brand-image-wrapper-position__right;
            width: $widget-hero-banner-brand-image-wrapper__width;
            height: $widget-hero-banner-brand-image-wrapper__height;
        }

        &-banner-text-headline {
            margin-top: $widget-hero-banner-text-headline__margin-top--large;
        }

        &-banner-text-description {
            > * {
                margin: $widget-hero-banner-text-description__margin;
            }
        }
    
        &-banner-button {
            @include get-font('compact');
            width: $widget-hero-banner-button__width--large;
            padding: $widget-hero-banner-button__padding--large;
        }

        // Hero Banner in Four Column Grid

        &-category-grid {
            .widget-banner-text-description {
                > * {
                    margin: $widget-hero-banner-four-column-text-description__margin--large;
                }
            }

            .widget-banner-button-wrapper {
                margin-bottom: $base-padding * 3.3;
            }

            .widget-banner-button {
                width: $widget-hero-banner-four-column-button__width--large;
                padding: $widget-hero-banner-four-column-button__padding--large;
            }

            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-content-wrapper__padding;
            }

            .widget-brand-image-wrapper {
                width: $widget-hero-banner-four-column-brand-image-wrapper__width;
                height: $widget-hero-banner-four-column-brand-image-wrapper__height;
            }
        }

        // Hero Banner in Category Matrix Layout

        &-category-matrix {
            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-category-matrix-content-wrapper__padding--large;
            }

            .widget-category-matrix-main-container {
                .widget-banner-button {
                    width: $widget-hero-banner-cat-matrix-button-big__width--large;
                    padding: $widget-hero-banner-cat-matrix-button__padding--large;
                }
            }

            .widget-category-matrix-side-container,
            .widget-category-matrix-bottom-container {
                .widget-banner-button {
                    width: $widget-hero-banner-cat-matrix-button-small__width--large;
                    padding: $widget-hero-banner-cat-matrix-button__padding--large;
                }
            }
        }

        // Hero Banner in Single Column
        
        &-single-column {
            .widget-hero-banner-container.small {
                .widget-banner-content-wrapper-overlay {
                    padding: $widget-hero-banner-single-column-content-wrapper__padding--large;
                }

                .widget-banner-button {
                    width: $widget-hero-banner-small-image-button__width--large;
                    padding: $widget-hero-banner-small-image-button__padding--large;
                }
            }

            .widget-banner-button-wrapper {
                margin-top: $widget-banner-button-wrapper__margin-top--large;
            }
        }

        // Category Tile Widget

        &-category-main-wrapper {
            height: auto;
        }

        &-category-heading-wrapper {
            min-height: $widget-category-heading-wrapper__min-height--large;
            padding: $widget-category-heading-wrapper__padding--large;
        }

        &-category-heading-text {
            @include get-font('almost-big');
        }

        &-subcategories {
            margin: $widget-subcategories__margin--large;
        }

        &-subcategory {
            > .widget-category-tile-link {
                padding-left: $widget-subcategory-link__padding-left--large;
            }
        }

        // HTML Block Widget

        &-html-block {
            &.text-indentation-enabled {
                width: $widget-assets-full__width / 2;
                margin: 0 auto;
            }

            &.text-indentation-enabled.right {
                margin: 0 0 0 auto;
            }

            &.left {
                margin: 0 auto 0 0;
            }

            &::before {
                font-size: $widget-html-block-icon__font-size--large;
            }
        }

        // Article Tile Widget

        &-article-date {
            @include get-font('xsmall');
        }

        &-article-title {
            @include get-font('medium');
            width: $widget-article-title__width--large;
            margin: $widget-article-title__margin;
        }

        // Goal Banner Widget

        &-goal-banner-background-wrapper {
            height: $widget-goal-banner-background-wrapper__height--large;
        }
    
        &-goal-banner-title {
            @include get-font('enormous');
            width: $widget-goal-banner-title__width--large;
            margin: $widget-goal-banner-title__margin--large;
        }
    
        &-goal-banner-link {
            @include get-font('medium');
            display: inline;
            padding-right: $widget-goal-banner-link__pading-right--large;
            font-weight: $widget-goal-banner-link__font-weight--large;

            &:last-child {
                padding-right: 0;
            }
        }
    }
}
