// =============================================================================
//
//  General Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

$html__background-color: $white;
$html__color: $black;
$html__font-weight: $normal-weight;

$wrapper__padding: 0 ($base-padding * 1.2);
$wrapper__padding--medium: 0 ($base-padding * 1.2);
$wrapper__padding--large: 0 ($base-padding * 9);

$wrapper-header__padding--large: 0 ($base-margin * 4.8);
$wrapper-header__padding--medium-large: 0 ($base-margin * 2.5);

$header-top__height: 3.5rem;
$header-top__height--large: 3rem;
$header-main__height: 8.5rem;
$header-main__height--large: 10rem;
$header-navigation__height--large: 5.5rem;

$page__padding-top: calc(#{$header-main__height});
$page__padding-top--large: calc(#{$header-top__height--large} + #{$header-main__height--large} + #{$header-navigation__height--large});

$account-user-logged-in__color: $black;
$account-user-logged-in__border-bottom: 0.4rem solid $black;
$account-user-logged-in__padding-bottom: $base-padding * 0.3;
$account-user-logged-in__padding-bottom--large: $base-padding * 1.2;
