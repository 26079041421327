// =============================================================================
//
//  General
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width-x-large) and (max-width: $desktop-width-large) {
    .wrapper {
        &-header {
            padding: $wrapper-header__padding--medium-large;
        }
    }
}
