// =============================================================================
//
//  General
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width-x-large) {
    .page {
        padding-top: $page__padding-top--large;

        &.checkout {
            padding-top: 0;
        }
    }

    .container {
        max-width: 144rem;
    }

    .wrapper {
        padding: $wrapper__padding--large;

        &-header {
            padding: $wrapper-header__padding--large;
        }
    }

    .header-login-link {
        &.account-user-logged-in {
            padding-bottom: $account-user-logged-in__padding-bottom--large;
        }
    }
}
