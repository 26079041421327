// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/widgets/assets';

.widget {

    // General

    &-image {
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        object-fit: cover;
        object-position: var(--focal-point-x) var(--focal-point-y);
    }

    // Hero Banner

    &-banner-image-wrapper {
        &.small {
            height: $widget-hero-banner-mobile-small__height;
        }

        &.medium {
            height: $widget-hero-banner-mobile-medium__height;
        }

        &.large {
            height: $widget-hero-banner-mobile-large__height;
        }

        &.full_height {
            height: calc(100vh - #{$page__padding-top});
        }
    }

    &-hero-banner-container {
        position: relative;
    }

    &-banner-label {
        @include get-font('micro');
        position: relative;
        z-index: 1;
        min-width: $widget-banner-label__min-width;
        padding: $widget-banner-label__padding;
        border-bottom-right-radius: $widget-banner-label__border-radius;
        font-weight: $widget-banner-label__font-weight;
        text-align: center;
        text-transform: uppercase;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0;
            border: $widget-banner-label-after__border;
            border-bottom: 0;
            border-left: 0;
            margin-bottom: $widget-banner-label-after__margin-bottom;
        }

        &.pd-background-white {
            &::after {
                border-top-color: $white;
            }
        }

        &.pd-background-black {
            &::after {
                border-top-color: $black;
            }
        }

        &.pd-background-red {
            &::after {
                border-top-color: $black;
            }
        }

        &.pd-background-sunflower-yellow {
            &::after {
                border-top-color: $black;
            }
        }

        &.pd-background-ocre {
            &::after {
                border-top-color: $grey;
            }
        }

        &.pd-background-light-gold {
            &::after {
                border-top-color: $dark-blue;
            }
        }

        &.pd-background-true-green {
            &::after {
                border-top-color: $light-blue;
            }
        }

        &.pd-background-very-light-pink {
            &::after {
                border-top-color: $grey;
            }
        }
    }

    &-banner-content-wrapper-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-hero-banner-full__width;
        height: $widget-hero-banner-full__height;
        padding: $widget-hero-banner-content-wrapper__padding;
        z-index: 2;
    }

    &-content-position {
        @include flexbox();
        @include flex-direction(column);
        position: absolute;
        width: $widget-bannner-content__width;

        &.center {
            @include align-items(center);
            text-align: center;

            .widget-banner-button-wrapper {
                @include justify-content(center);
            }
        }

        &.right {
            @include align-items(flex-end);
            text-align: right;

            .widget-banner-button-wrapper {
                @include justify-content(center);
            }
        }

        &.mobile {
            &-top {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &-center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &-bottom {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &-banner-text-description {
        font-family: $scene-pro-bold;
        letter-spacing: $widget-banner-description__letter-spacing;
        text-transform: uppercase;

        > * {
            margin: 0;
        }
    }

    &-single-column {
        .widget-banner-button-wrapper {
            margin-top: $widget-banner-button-wrapper__margin-top;
        }
    }

    &-banner-content-wrapper {
        @include flexbox();
        @include align-items(flex-start);
        @include flex-direction(column);
        position: relative;
        width: $widget-hero-banner-full__width;
        height: $widget-hero-banner-full__height;
    }

    &-brand-link {
        @include get-font('mini');
        position: relative;
        z-index: 1;
        margin-top: $widget-hero-banner-brand-text__margin_top;
        color: $widget-hero-banner-brand-text__color;
        text-decoration: none;
    }

    &-banner-text-headline {
        margin-top: $widget-hero-banner-text-headline__margin-top;

        h1,
        h2,
        h3,
        h4 {
            font-family: $scene-pro-black;
        }
    }

    &-banner-button-wrapper {
        @include flexbox();
        @include align-items(flex-end);
        position: relative;
        z-index: 1;
        margin-top: auto;
    }

    &-banner-button {
        @include get-font('xsmall-small');
        padding: $widget-hero-banner-button__padding;
        font-family: $scene-pro-bold;
        font-weight: $widget-hero-banner-button__font-weight;
        text-align: center;
        text-decoration: none;

        &.pd-background-black {
            &:hover {
                background-color: $widget-hero-banner-button-hover-black__background-color !important;
            }
        }

        &.pd-background-sunflower-yellow {
            &:hover {
                background-color: $widget-hero-banner-button-hover-yellow__background-color !important;
            }
        }
    }

    &-banner-global-link {
        position: relative;
        z-index: 1;
        width: $widget-assets-full__width;
        text-decoration: none;

        .widget-image {
            display: block;
        }
    }

    &-banner-hiden-link {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
    }

    &-picture-wrapper {
        display: initial;
    }

    // Video Block

    &-video-block-container {
        position: relative;
        overflow: hidden;
        padding-top: $widget-video-responsive__pading-top;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: $widget-video-block-full__width;
            height: $widget-video-block-full__height;
            border: 0;
        }
    }

    // Category Tile Widget

    &-category-main-wrapper {
        .widget-category-tile-link {
            text-decoration: none;
        }
    }

    &-category-heading-wrapper {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        position: relative;
        top: $widget-category-heading-wrapper__top;
        left: 0;
        width: $widget-category-tile-full__width;
        padding: $widget-category-heading-wrapper__padding;
        background-color: $widget-category-heading-wrapper__background-color;
    }

    &-category-heading-text {
        @include get-font('small');
        font-family: $scene-pro-bold;
        font-weight: $widget-category-heading-text__font-weight;
        text-transform: uppercase;
        word-break: break-word;
    }

    &-subcategories {
        padding: 0;
        margin: $widget-subcategories__margin;
        list-style-type: none;
    }

    &-subcategory {
        @include get-font('small');
        @include flexbox();
        @include align-items(center);
        padding-bottom: $widget-subcategory__padding-bottom;
        color: $widget-subcategory__color;
        text-transform: capitalize;
        word-break: break-word;

        &:last-child {
            padding-bottom: 0;
        }

        > .widget-category-tile-link {
            @include get-font('small');
            padding-left: $widget-subcategory-link__padding-left;
            font-family: $scene-pro-bold;
            text-decoration: underline;
        }
    }

    // Article Tile Widget

    &-article-tile-top-content-wrapper,
    &-article-tile-bottom-content-wrapper {
        padding: 0;
    }

    &-article-tile-top-content-wrapper {
        margin-top: $article-tile-top-content-wrapper__margin-top;
    }

    &-article-link-wrapper {
        display: block;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        margin-bottom: $widget-article-link__margin-bottom;
        text-decoration: none;

        &:hover {
            color: inherit;

            .widget-image-hover-overlay {
                background-color: $widget-image-hover-overlay__background-color;;
            }

            .widget-article-title {
                color: $widget-article-elements-hover__color;
            }
        }
    }

    &-article-tile-image-wrapper {
        position: relative;
    }

    &-image-hover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    &-article-description {
        > p {
            margin: 0;
            color: $article-description__color;
            line-height: $article-description__line-height;
        }
    }

    &-article-date {
        @include get-font('xsmall');
        display: block;
        margin-top: $widget-article-date__margin-top;
        color: $widget-article-date__color;
    }

    &-article-title {
        @include get-font('compact');
        width: $widget-article-title__width;
        margin: $widget-article-title__margin;
        color: $article-title__color;
        transition: color 0.3s ease;
    }

    &-article-categories {
        @include get-font('xsmall');
        color: $widget-article-categories__color;
        font-weight: $widget-article-categories__font-weight;
        text-decoration: none;

        &::after {
            content: '';
            position: relative;
            right: $widget-article-categories-separator-after__right;
            display: inline-block;
            width: $widget-article-categories-separator-after__width;
            height: $widget-article-categories-separator-after__height;
            margin: $widget-article-categories__margin;
            background-color: $widget-article-categories__color;
            vertical-align: middle;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }

    // Affiliate Widget

    &-affiliate-tile-wrapper {
        padding: $widget-affiliate-tile-wrapper__padding;
        border: $widget-affiliate-tile-wrapper__border;
        border-radius: $widget-affiliate-tile-wrapper__border-radius;
        margin: $widget-affiliate-tile-wrapper__margin;
        text-align: center;

        .widget-image {
            display: inline-block;
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }

    &-affiliate-heading-text {
        margin: $widget-affiliate-heading-text__margin;
        font-weight: $widget-affiliate-heading-text__font-weight;
    }

    &-affiliate-url-wrapper {
        word-break: break-all;

        a {
            line-height: 1.5;
            text-decoration: none;
        }
    }

    // Goal Banner Widget

    &-goal-banner-container {
        position: relative;
    }

    &-goal-banner-content {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        text-align: center;
    }

    &-goal-banner-background-inner-wrapper {
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
    }

    &-goal-banner-background-wrapper {
        height: $widget-goal-banner-background-wrapper__height;
    }

    &-goal-banner-title {
        @include get-font('large');
        width: $widget-goal-banner-title__width;
        margin: $widget-goal-banner-title__margin;
    }

    &-goal-banner-link {
        @include get-font('compact');
        display: block;
        margin-bottom: $widget-goal-banner-link__margin-bottom;
        color: $widget-goal-banner-link__color;
        text-decoration-color: $widget-goal-banner-link__color;
    }

    // Html Block

    &-html-block {
        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        &::before {
            color: $widget-html-block-icon__color;
            font-size: $widget-html-block-icon__font-size;
        }

        h1,
        h2,
        h3,
        h4,
        p {
            > a {
                font-size: inherit;
            }
        }
    }

    &-layout-html-block-column {
        .widget-html-block {
            margin-bottom: $widget-html-block__margin-bottom;

            &.text-indentation-enabled {
                margin-bottom: $widget-html-block__margin-bottom;
            }
        }

        .experience-hsngassets-htmlblock {
            &:last-child {
                .widget-html-block,
                .widget-html-block.text-indentation-enabled {
                    margin-bottom: 0;
                }
            }
        }
    }

}

@import '../../screens/medium/components/widgets/assets';
@import '../../screens/large/components/widgets/assets';
@import '../../screens/medium-large/components/widgets/assets';
